import { Component, Input } from '@angular/core';
import { QRIcon } from '@app/core/models/qr-icon.model';

@Component({
  selector: 'qr-icon',
  standalone: true,
  templateUrl: './qr-icon.component.html',
  styleUrl: './qr-icon.component.scss',
})
export class QRIconComponent {
  @Input() config?: QRIcon;
}
